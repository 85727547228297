export const BG_PRIMARY = 'rgba(40,73,116,1)';
export const BG_PRIMARY_GRADIENT = 'rgba(40,73,116,1)';
export const BG_SECONDARY = 'rgba(218,228,236,1)';

export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = 'rgba(23,59,105,1)';

export const BUTTON_COLOR_TEXT = '#000';

// Icon & Navigation
export const BG_NAVIGATION = `#7e99bc`;
export const BG_NAVIGATION_ICON_COLOR = `#fff`;

// DEFAULT STYLES
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};